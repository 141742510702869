<template>
  <v-card class="mx-auto" disabled>
    <v-card-title>
      <p>Sample Card</p>
    </v-card-title>

    <div class="main">
      <canvas id="chart" ref="chart"></canvas>
    </div>

    <v-divider></v-divider>

    <v-card-text>
      <h2 class="text-h6 primary--text">Sample</h2>
      Lorem etc.
    </v-card-text>
    <div class="wip">Work in Progress</div>
  </v-card>
</template> 

<script>
import { CategoryScale, Chart, LineController, LineElement, PointElement } from "chart.js";
import { colors } from "vuetify/lib";

Chart.register(LineController, CategoryScale, PointElement, LineElement);
var chart;

export default {
  name: "LineChartCardComponent",
  data() {
    return { chart };
  },
  mounted() {
    var ctx = this.$refs.chart.getContext("2d");

    this.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: ["1", "2", "3"],
        legend: {
          position: "top",
        },
        datasets: [
          {
            label: "TestLabel",
            data: [5, 8, 9],
            fill: false,
            borderColor: colors.blue.base,
            tension: 0.1,
          },
          {
            label: "Secondary",
            data: [1, 3, 6],
            fill: false,
            borderColor: colors.red.base,
            tension: 0.1,
          },
        ],
      },
    });
  },
  destroyed: function () {
    if (!this.chart?.destroyed) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped>
.main {
  height: 100%;
}

.wip {
  position: absolute;
  font-size: larger;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>