import { DataFlow } from './data-flow';
export class SankeyDataPointHolder {
    readonly from: string
    readonly to: string
    readonly flow: number
    // values contain the name, but with the year suffix removed (if present)
    // for display only! (thus label)
    readonly fromLabel: string
    readonly toLabel: string

    constructor(dataFlow: DataFlow) {
        this.from = dataFlow.source
        this.to = dataFlow.target
        this.flow = dataFlow.value
        if (this.endsWithNumber(this.from)) {
            this.fromLabel = this.from.substring(0, this.from.length - 4)
            this.toLabel = this.to.substring(0, this.to.length - 4)
        } else {
            this.fromLabel = this.from.trim()
            this.toLabel = this.to.trim()
        }
    }

    endsWithNumber(str: string) {
        return /[0-9]+$/.test(str);
    }

    equalTo(otherFlow: SankeyDataPointHolder): Boolean {
        return this.from == otherFlow.from && this.to == otherFlow.to
    }

}