export const de = {
    homeview: {
        search: "Suchen",
        to: "Bis",
        from: "Von",
        topicSelect: "Topic wählen",
        fieldSelect: "Themenfeld wählen",
        chosenTopicHint: "Gewähltes Topic:"
    },
    sankeyCard: {
        mode: "Modus"
    },
}