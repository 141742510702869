export interface Person {
    name: string,
    description?: string,
    website?: string,
    email: string
}

export const persons: Person[] = [
    {
        name: "Tom Hanika",
        website: "https://www.kde.cs.uni-kassel.de/hanika",
        email: "Tom.Hanika@cs.uni-kassel.de"
    },
    {
        name: "Johannes Hirth",
        website: "https://www.kde.cs.uni-kassel.de/hirth",
        email: "hirth@cs.uni-kassel.de"
    },
    {
        name: "Bastian Schäfermeier",
        website: "https://www.kde.cs.uni-kassel.de/schaefermeier",
        email: "bsc@cs.uni-kassel.de"
    },
    {
        name: "Maurice Hartmann",
        email: "mh2207waba@gmail.com"
    }
]