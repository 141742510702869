import { SankeyFlowSource } from './../models/data/sankey-flow-source';
import { DataFlow, flowsAreEqual } from './../models/data/data-flow';
import { Config } from './../models/config/config';
import axios from 'axios';

export default new class DataService {

    config: Promise<Config>
    private baseUrl = ""

    constructor() {
        this.config = Promise.resolve(this.loadConfig())
        this.config.then(cfg => {
            this.baseUrl = cfg.baseUrl ? cfg.baseUrl : ""
        })
    }

    async getData(field: string, resolution: number, flowSize: number = 25, yearFrom = 1960, yearTo = 1960): Promise<SankeyFlowSource> {
        let dataFlowOriginal: DataFlow[] = []
        let dataFlowNormalized: DataFlow[] = []
        for (let i = yearFrom; i <= yearTo; i++) {
            //fetch data as json
            const content = await axios(this.baseUrl + this.buildFileName(field.toLocaleLowerCase(), resolution, flowSize, i))
            const currentData: DataFlow[] = Object.values(await content.data)

            // gather sum of values
            const normalizationValue = currentData.reduce((value, data) => { return value += data.value }, 0)
            if (dataFlowNormalized.length > 0) {
                const result = this.updateFlows(currentData, dataFlowNormalized, dataFlowOriginal, normalizationValue);
                dataFlowOriginal = result.dataFlowOriginal
                dataFlowNormalized = result.dataFlowNormalized
            } else {
                currentData.forEach(data => {
                    this.appendNewFlowValues(dataFlowOriginal, data, normalizationValue, dataFlowNormalized);
                })
            }
        }
        return { yearFrom, yearTo, normalizedData: dataFlowNormalized, originalData: dataFlowOriginal }
    }

    // TODO: Optimize
    async getDataForTopicWithAdditionalInfluence(field: string, resolution: number, flowSize: number, topic: string[], intervall: { yearFrom: number, yearTo: number }[], data?: SankeyFlowSource): Promise<SankeyFlowSource | undefined> {
        const currentYears = intervall.shift()
        if (!currentYears) return data
        const newData = await this.getData(field, resolution, flowSize, currentYears.yearFrom, currentYears.yearTo);
        if (!data) {
            data = newData
            const columns = new Map()
            data.displayedYears = [currentYears.yearFrom + (currentYears.yearFrom == currentYears.yearTo ? "" : "-" + currentYears.yearTo)]
            data.originalData = data.originalData.filter(data => {
                return data.source.includes(topic[0])
            })
            data.normalizedData = data.normalizedData.filter(data => {
                return data.source.includes(topic[0])
            })
            data.normalizedData.map(data => {
                data.source += currentYears.yearFrom
                data.target += currentYears.yearTo + 1
                columns.set(data.source, 0)
                columns.set(data.target, 1)
            })
            data.originalData.map(data => {
                data.source += currentYears.yearFrom
                data.target += currentYears.yearTo + 1
            })
            data.normalizedData.forEach(flow => {
                topic.push(flow.target)
            })

            data.columnMap = columns
            if (data.originalData.length === 0) {
                data = undefined // Restart from beginning
            }
            return this.getDataForTopicWithAdditionalInfluence(field, resolution, flowSize, topic, intervall, data)
        } else {
            const columns = data.columnMap ? data.columnMap : new Map()
            data.displayedYears?.push(currentYears.yearFrom + (currentYears.yearFrom == currentYears.yearTo ? "" : "-" + currentYears.yearTo))
            const currentPriority = Math.max(...columns!.values()) + 1
            // Always keep selected topic as direct flow
            topic.push(topic[0] + currentYears.yearFrom)
            columns!.set(topic[0] + currentYears.yearFrom, currentPriority - 1)
            newData.normalizedData.map(data => {
                data.source += currentYears.yearFrom
                data.target += currentYears.yearTo + 1
            })
            newData.originalData.map(data => {
                data.source += currentYears.yearFrom
                data.target += currentYears.yearTo + 1
            })
            data.normalizedData.push(...newData.normalizedData.filter(flow => {
                return topic.includes(flow.source) && !topic.includes(flow.target)
            }))
            data.originalData.push(...newData.originalData.filter(flow => {
                return topic.includes(flow.source) && !topic.includes(flow.target)
            }))
            data.normalizedData.forEach(flow => {
                if (columns!.get(flow.target) == undefined) {
                    columns!.set(flow.target, currentPriority)
                }
                topic.push(flow.target)
            })
            data.columnMap = columns
            return this.getDataForTopicWithAdditionalInfluence(field, resolution, flowSize, topic, intervall, data)
        }

    }

    /** 
     * Adds values from one year to already existing data flow
     * Usually only relevant for normalized data
     */
    private updateFlows(currentData: DataFlow[], dataFlowNormalized: DataFlow[], dataFlowOriginal: DataFlow[], normalizationValue: number) {
        currentData.forEach(data => {
            let equalFlowFound = false
            dataFlowNormalized.forEach(nData => {
                if (flowsAreEqual(nData, data)) {
                    nData.value += (data.value / normalizationValue);
                    equalFlowFound = true
                }
            });
            dataFlowOriginal.forEach(oData => {
                if (flowsAreEqual(oData, data)) {
                    oData.value += data.value;
                }
            });
            // If flow was not updated this is a new flow which needs to be added
            if (!equalFlowFound) {
                this.appendNewFlowValues(dataFlowOriginal, data, normalizationValue, dataFlowNormalized)
            }
        });
        return { dataFlowNormalized, dataFlowOriginal }
    }

    private async loadConfig(): Promise<Config> {
        const content = await axios("config.json")
        return await content.data
    }

    async getTopics(fieldName: string, resolution: number, topicFile: string): Promise<string[]> {
        const content = await axios(this.baseUrl + `${fieldName}/${resolution}/${topicFile}`)
        return await content.data
    }

    private buildFileName(field: string, resolution: number, pageSize = 50, year = 1960) {
        return `${field}/${resolution}/${field}_${year}_${pageSize}.json`
    }


    private appendNewFlowValues(dataFlowOriginal: DataFlow[], data: DataFlow, normalizationValue: number, dataFlowNormalized: DataFlow[]) {
        dataFlowOriginal.push({ ...data });
        data.value = data.value / normalizationValue;
        dataFlowNormalized.push(data);
    }
}