import { en } from './english';
import { de } from "./german"

export enum Locales {
    EN = 'en',
    FR = 'fr',
    DE = 'de'
  }
  
  export const LOCALES = [
    { value: Locales.EN, caption: 'English' },
    { value: Locales.FR, caption: 'Français' },
    { value: Locales.DE, caption: 'Deutsch' }
  ]

  export const messages = {
    [Locales.DE]: de,
    [Locales.EN]: en
  }