import { messages, Locales } from './i18n/i18n-setup';

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

const VueMarkdown = require('@adapttive/vue-markdown')

new Vue({
  components: {
    VueMarkdown
  }
})

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueMarkdown)



const i18n = new VueI18n({
  messages: messages,
  locale: Locales.EN
})


const vue = new Vue({
  i18n,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

