export const en = {
    homeview: {
        search: "show",
        to: "To",
        from: "From",
        topicSelect: "Choose Topic",
        resolutionSelect: "Choose Resolution",
        fieldSelect: "Choose Field",
        chosenTopicHint: "Chosen Topic:"
    },
    sankeycard: {
        mode: "Mode"
    },
    $vuetify: {

    }
}