import { DataFlow } from './data-flow';
export interface SankeyFlowSource {
    yearFrom: number,
    yearTo: number,
    normalizedData: DataFlow[],
    originalData: DataFlow[],
    topic?: string,
    columnMap?: Map<string, number>
    displayedYears?: string[]
}

export function mapToObject(map: Map<string, number> | undefined): any {
    const obj: any = {}
    if (!map) return obj
    for (const pair of map.entries()) {
        obj[pair[0]] = pair[1]
    }
    return obj
}